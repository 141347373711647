import { Injectable, TemplateRef } from '@angular/core';
import { ToastOptions } from '../../widgets/toast/ToastOptions';


@Injectable({
   providedIn: 'root'
})


export class ToastService {

   constructor() { }


   toasts: any[] = [];


   show(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {} as ToastOptions) {

      // Reset toasts
      this.toasts = [];

      // Display toast
      this.toasts.push({ textOrTpl, ...options });

      // TODO: Add back to display multiple toasts at once, make sure that closing the toast via the 'x' removes it from the list
      /* let toastExists = this.toasts.filter(t => t.textOrTpl == textOrTpl).length > 0;
      if (!toastExists) this.toasts.push({ textOrTpl, ...options }); */

   }


   remove(toast) {
      this.toasts = this.toasts.filter(t => t !== toast);
   }


   dismissAll(): void {
      this.toasts = [];
   }

}
