import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })


export class AuthenticationService {

   user: User;


   constructor(
      private http: HttpClient,
      private cookieService: CookieService,
      private router: Router
   ) { }


   public currentUser(): User {
      if (!this.user) {
         this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
      }
      return this.user;
   }


   public isLoggedIn(): boolean {

      let isLoggedIn = false;

      try {
         isLoggedIn = JSON.parse(localStorage.getItem("Auth")).isLoggedIn;
      } 
      catch (err) {
         isLoggedIn = false;
      }

      return isLoggedIn;

   }


   login(username: string, password: string): boolean {
      if (username == "ElnileinAdmin" && password == "ElnileinPassword") { // TODO: I KNOW!

         // Save auth flag
         localStorage.setItem("Auth", JSON.stringify({ isLoggedIn: true }));

         // Navigate to list
         this.router.navigate(["/providers/list"]);

      } else {
         return false;
      }
   }


   logout() {
      // remove user from local storage to log user out
      this.cookieService.deleteCookie('currentUser');
      this.user = null;
   }

}
