import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/widgets/toast.service';


@Component({
   selector: 'app-toasts',
   templateUrl: './toast.component.html',
   styleUrls: ['./toast.component.scss']
})


export class ToastComponent implements OnInit {

   constructor(
      public toastService: ToastService
   ) { }


   ngOnInit(): void {
   }


   isTemplate(toast) { 
      return toast.textOrTpl instanceof TemplateRef;
   }

}
